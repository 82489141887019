/* // @import "../../variables.scss"; */

.toggle-btn {
  padding: 10px;
  cursor: pointer;
}

.nav-links {
  position: fixed;
  top: 0;
  left: -250px;
  width: 250px;
  height: 100%;
  background-color: white;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  transition: left 0.3s ease-in-out;
}

.open {
  left: 0;
}

.nav-links a {
  display: block;
  margin-bottom: 15px;
  text-decoration: none;
  color: black;
  font-size: 18px;
}
