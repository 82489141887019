.custom-dashed {
  border-style: dashed;
  border-width: 1px;
  border-color: #6b7280; /* Tailwind's gray-500 */

  border-image-slice: 10;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
.Housinganimate-bounce {
  animation: bounce 6s infinite;
}
