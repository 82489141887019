@import "../variables.scss";

.Schoolscontainer {
  //   width: 100%;
  height: 30rem;
  overflow: scroll;
  @include breakpoint(mobileonly) {
    height: 15rem;
  }

  &__schoolData {
    display: grid;
    grid-template-columns: auto auto;
  }
}
