@import "../../variables.scss";

.flight-icon {
  /* width: 50px;
  height: 50px;
  background-color: #0073e6; */
  /* border-radius: 50%;
  position: relative; */
  animation: flightUp 2s ease-in-out infinite;
}

@keyframes flightUp {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100%);
  }
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  overflow: hidden;
}
.background li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.3);
  animation: animate 8s linear infinite;
}

.background li:nth-child(0) {
  left: 16%;
  width: 127px;
  height: 127px;
  bottom: -127px;
  animation-delay: 1s;
}
.background li:nth-child(1) {
  left: 62%;
  width: 186px;
  height: 186px;
  bottom: -186px;
  animation-delay: 2s;
}
.background li:nth-child(2) {
  left: 24%;
  width: 138px;
  height: 138px;
  bottom: -138px;
  animation-delay: 7s;
}
.background li:nth-child(3) {
  left: 42%;
  width: 122px;
  height: 122px;
  bottom: -122px;
  animation-delay: 12s;
}
.background li:nth-child(4) {
  left: 16%;
  width: 129px;
  height: 129px;
  bottom: -129px;
  animation-delay: 16s;
}
.background li:nth-child(5) {
  left: 16%;
  width: 157px;
  height: 157px;
  bottom: -157px;
  animation-delay: 7s;
}
.background li:nth-child(6) {
  left: 67%;
  width: 109px;
  height: 109px;
  bottom: -109px;
  animation-delay: 28s;
}
.background li:nth-child(7) {
  left: 2%;
  width: 171px;
  height: 171px;
  bottom: -171px;
  animation-delay: 27s;
}
.background li:nth-child(8) {
  left: 54%;
  width: 151px;
  height: 151px;
  bottom: -151px;
  animation-delay: 5s;
}
.background li:nth-child(9) {
  left: 71%;
  width: 142px;
  height: 142px;
  bottom: -142px;
  animation-delay: 44s;
}

.shimmer {
  // font-family: "poppins";
  font-weight: 600;
  // font-size: 20px;
  display: inline;
}

.shimmer {
  text-align: center;

  color: rgba(255, 255, 255, 0.102);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(255, 255, 255, 0.8)),
    to(rgba(255, 255, 255, 0.8)),
    color-stop(0.5, rgba(255, 255, 255, 0.8))
  );
  background: -moz-gradient(
    linear,
    left top,
    right top,
    from(rgba(255, 255, 255, 0.8)),
    to(rgba(255, 255, 255, 0.8)),
    color-stop(0.5, rgba(255, 255, 255, 0.8))
  );
  background: gradient(
    linear,
    left top,
    right top,
    from(rgba(255, 255, 255, 0.8)),
    to(rgba(255, 255, 255, 0.8)),
    color-stop(0.5, rgba(255, 255, 255, 0.8))
  );
  -webkit-background-size: 5px 100%;
  -moz-background-size: 5px 100%;
  background-size: 5px 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-animation-name: shimmer;
  -moz-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: rgb(103, 169, 72);
}

@-moz-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

@-webkit-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

@-o-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

@keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
