@import "../../variables.scss";

.visaFormSectionContainer {
  width: calc(100% - 20px);
  height: 100%;
  padding: 20px;
  margin-left: 20px;
  border-radius: 10px;
  border: 0.5px solid #67a948;

  &__subsections {
    background: #f7fff3;
    border: 1px solid #67a948;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
  }

  &__subsectionsFlex {
    width: 100%;

    display: flex;
    justify-content: space-between;
  }
}

.visaUploadInputs {
  border-radius: 6px;
  border: 1px dashed var(--Gray-400, #98a2b3);
  padding: 12px 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #67a948;
  font-weight: 500;
  cursor: pointer;
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  50% {
    transform: translateX(10%);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

.visaFileLimitError {
  background-color: #fff9f0;
  color: #bb8211;
  font-weight: 500;
  display: flex;
  gap: 6px;
  align-items: center;
  padding: 5px 20px;
  animation: slideIn 4s ease-in-out, slideOut 4s ease-in-out 10s forwards;
  border: 0.5px solid #ffbe3e;
  border-radius: 6px;
  font-size: 14px;
}

.Hb1paymentSection {
  border: 1px solid #d0d5dd;
  margin: 10px 0px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  //   gap: 10px;
  font-size: 14px;
}

.noteSection {
  background: url("../../assets/visaForm/Pattern.svg");
  width: 100%;
  height: 100%;
  padding: 20px;
}

.pay-icon {
  animation: payarrow 1s ease-in-out infinite;
}

@keyframes payarrow {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(70%);
  }
}
