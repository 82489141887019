/* .slider-container {
    overflow: hidden;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  
  .slider-item {
    overflow: hidden;
  } */

  .curved-image {
    /* border-radius: 20px;  */
    transform: perspective(600px) rotateY(10deg) rotateX(-5deg); /* Adjust the perspective and rotations to your preference */
    transition: transform 0.3s ease;
  }
  
  /* .curved-image:hover {
    transform: perspective(600px) rotateY(0deg) rotateX(0deg); 
  } */

  .custom-scrollbar::-webkit-scrollbar {
    width: 8px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #67A948; 
    border-radius: 4px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: #E2E8F0; 
  }