@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

// body {
//   font-size: 16px !important;
// }
// Colors

$vesti-100: #f7fff3;
$vesti-200: #dfffd0;
$vesti-300: #c7ffad;
$vesti-400: #b0ff8b;
$vesti-500: #97e373;
$vesti-600: #7ec65c;
$vesti-700: #67a948;
$vesti-800: #518c36;
$vesti-900: #3e6f26;
$vesti-901: #2b5219;
$vesti-902: #14290a;

$vestisec-100: #f6f7ff;
$vestisec-902: #060e42;

$vpurp-100: #fbf3ff;
$vpurp-200: #f0d1ff;
$vpurp-300: #e5aeff;
$vpurp-400: #d98bff;
$vpurp-500: #be73e2;
$vpurp-600: #a35cc5;
$vpurp-700: #8948a8;
$vpurp-800: #6f368b;
$vpurp-900: #57266e;
$vpurp-901: #3f1951;
$vpurp-902: #20082b;

$gray-100: #f9f8f9;
$gray-200: #f8f6f5;
$gray-300: #eaeaea;
$gray-400: #cccccc;
$gray-500: #8b8c8c;
$gray-600: #4f4f4f;
$gray-700: #2d2f30;
$gray-800: #151617;

$green-100: #f0fff6;
$green-200: #ceffe3;
$green-300: #adffd0;
$green-400: #8cffbd;
$green-500: #69fca8;
$green-600: #4dda89;
$green-700: #35b86d;
$green-800: #219653;
$green-900: #12743c;
$green-901: #075227;

$yellow-100: #fffaed;
$yellow-200: #fff1c6;
$yellow-300: #ffe79f;
$yellow-400: #ffdd77;
$yellow-500: #f2c94c;
$yellow-600: #d0aa38;
$yellow-700: #ae8c26;
$yellow-800: #8c6f18;
$yellow-900: #6a530d;
$yellow-901: #483806;

$sila-100: #f0f2ff;
$sila-200: #e5eaff;
$sila-300: #b8c4ff;
$sila-400: #a3affa;
$sila-500: #6f7fff;
$sila-600: #4c5ff7;
$sila-700: #4751d1;
$sila-800: #4d58b2;
$sila-900: #2c3684;

$red-100: #fff5f5;
$red-200: #fde7e8;
$red-300: #ffc2c5;
$red-400: #faa3a7;
$red-500: #f7787e;
$red-600: #ec575e;
$red-700: #d1474e;
$red-800: #c43138;
$red-900: #66191d;
$red-901: #430a0c;
$red-902: #2e0507;

$cyan-100: #edfdff;
$cyan-200: #c4f8ff;
$cyan-300: #9cf4ff;
$cyan-400: #74efff;
$cyan-500: #4ae7fa;
$cyan-600: #34c6d8;
$cyan-700: #22a6b6;
$cyan-800: #138694;
$cyan-900: #096672;
$cyan-901: #011c20;

$sec-100: #f6f7ff;
$sec-200: #e7eaff;
$sec-300: #d8ddff;
$sec-400: #c9d0ff;
$sec-500: #bac3ff;
$sec-600: #aab6ff;
$sec-700: #7f8ce5;
$sec-800: #5261bc;
$sec-900: #303d94;
$sec-901: #16216b;
$sec-902: #060e42;

//flex

@mixin rowflex($align, $just) {
  display: flex;
  flex-direction: row;
  align-items: $align;
  justify-content: $just;
}

@mixin rowRevflex($align, $just) {
  display: flex;
  flex-direction: row-reverse;
  align-items: $align;
  justify-content: $just;
}

@mixin colflex($align, $just) {
  display: flex;
  flex-direction: column;
  align-items: $align;
  justify-content: $just;
}
@mixin revcolflex($align, $just) {
  display: flex;
  flex-direction: column-reverse;
  align-items: $align;
  justify-content: $just;
}

@mixin rowflexx($align) {
  display: flex;
  flex-direction: row;
  align-items: $align;
}
@mixin rowflexxx($align) {
  display: flex;
  flex-direction: row;
  align-items: $align;
  justify-content: space-between;
}

@mixin colflexx($align) {
  display: flex;
  flex-direction: column;
  align-items: $align;
}
@mixin colflexxx($align) {
  display: flex;
  flex-direction: column-reverse;
  align-items: $align;
}

// font styles

@mixin font($fs, $lh, $color) {
  font-size: $fs;
  line-height: $lh;
  color: $color;
  font-style: normal;
  font-family: "lato", sans-serif;
}

@mixin fontMain($fs, $lh, $color) {
  font-size: $fs;
  line-height: $lh;
  color: $color;
  font-style: normal;
  font-family: "Khand", sans-serif;
}
@mixin fontLight($fs, $lh, $color) {
  @include font($fs, $lh, $color);
  font-weight: 100;
}

@mixin fontNormal($fs, $lh, $color) {
  @include font($fs, $lh, $color);
  font-weight: 300;
}

@mixin fontMedium($fs, $lh, $color) {
  @include font($fs, $lh, $color);
  font-weight: 400;
}

@mixin fontSemiBold($fs, $lh, $color) {
  @include font($fs, $lh, $color);
  font-weight: 600;
}

@mixin fontBold($fs, $lh, $color) {
  @include font($fs, $lh, $color);
  font-weight: 700;
}

@mixin fontBlack($fs, $lh, $color) {
  @include font($fs, $lh, $color);
  font-weight: 900;
}

@mixin fontTitleMedium($fs, $lh, $color) {
  @include fontMain($fs, $lh, $color);
  font-weight: 400;
}
@mixin fontTitleBold($fs, $lh, $color) {
  @include fontMain($fs, $lh, $color);
  font-weight: 500;
}

@mixin fontTitleBlack($fs, $lh, $color) {
  @include fontMain($fs, $lh, $color);
  font-weight: 700;
}

// keyframes

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-o-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@include keyframes(slide-in) {
  0% {
    opacity: 0;
    padding-top: 20px;
    @include animation("fade 0.7s 1");
  }

  100% {
    padding-top: 0px;
    @include animation("fade 0.7s 1");
  }
}

@include keyframes(fade) {
  0% {
    opacity: 0;
  }

  90% {
    padding-top: 1;
  }
}

@mixin animate($name, $duration, $iteration, $direction) {
  -webkit-animation-duration: $duration;
  -moz-animation-duration: $duration;
  -o-animation-duration: $duration;
  animation-duration: $duration;
  -webkit-animation-iteration-count: $iteration;
  -moz-animation-iteration-count: $iteration;
  -o-animation-iteration-count: $iteration;
  animation-iteration-count: $iteration;
  -webkit-animation-name: $name;
  -moz-animation-name: $name;
  -o-animation-name: $name;
  animation-name: $name;
  -webkit-animation-direction: $direction;
  -moz-animation-direction: $direction;
  -o-animation-direction: $direction;
  animation-direction: $direction;
}

/*********************
BREAKPOINTS
*********************/

@mixin breakpoint($point) {
  @if $point == desktop {
    @media (min-width: 70em) {
      @content;
    }
    //
  } @else if $point == laptop {
    @media (min-width: 64em) {
      @content;
    }
    // @media (min-width: 1024) { @content ; }
  } @else if $point == tablet {
    // @media (min-width: 50em) { @content ; }
    @media (min-width: 800px) and (max-width: 1224px) {
      @content;
    }
    // @media (min-width: 768px) and (max-width: 1024px) {@content;}
  } @else if $point == phablet {
    // @media (min-width: 37.5em)  { @content ; }
    // @media (min-width: 480px) and (max-width: 768px) { @content; }
    @media (min-width: 600px) and (max-width: 800px) {
      @content;
    }
  } @else if $point == mobileonly {
    // @media (max-width: 37.5em)  { @content ; }
    // @media (min-width: 320px) and (max-width: 480px) { @content; }
    @media (min-width: 300px) and (max-width: 600px) {
      @content;
    }
  }
}

// wallet mixin
// @mixin wallet($primary, $light, $medium, $semidark, $dark) {
//   border: 1px solid $primary;
//   background-color: $light;
//   @include colflex(flex-start, space-between);
//   .singlewallet__top {
//     height: 100px;

//     @include colflex(flex-start, space-between);

//     &__title {
//       text-transform: uppercase;
//       margin: 0;
//       @include fontNormal(1em, 1em, $dark);
//     }
//     &__amount {
//       margin-top: 10px;
//       @include rowflexx(flex-start);

//       span {
//         margin-right: 20px;
//         @include rowflexx(flex-end);

//         p:nth-child(1) {
//           margin: 0;
//           margin-bottom: 2px;
//           @include fontSemiBold(1.5em, 1em, $medium);
//         }
//         p:nth-child(2) {
//           margin: 0;
//           margin-left: 10px;
//           @include fontBold(3.5em, 1em, $semidark);
//         }
//       }
//       i {
//         font-size: 2em;
//         color: $primary;
//         transition: all 0.5s ease;
//       }
//     }
//   }
//   .singlewallet__link {
//     @include rowflexx(center);
//     @include fontMedium(1em, 1em, $primary);
//     cursor: pointer;

//     &:hover {
//       @include fontMedium(1em, 1em, $semidark);
//     }
//     i {
//       margin-left: 5px;
//       font-size: 1.5em;
//     }
//   }
// }

// @mixin wallets($bkg, $primary, $btn, $btncolor) {
//   border: 1px solid $primary;
//   background-color: $bkg;
//   // @include colflex(flex-start,space-between);

//   .wallet {
//     // height: 310px;
//     height: 252px;
//     @include colflexx(flex-start);
//     gap: 40px;
//     border: 1px solid $primary;
//     // background-color: $bkg;
//     padding: 30px 40px;

//     &__top {
//       @include colflex(flex-start, space-between);
//       gap: 10px;
//       width: 100%;
//     }
//     &__topinner {
//       width: 100%;
//       @include rowflex(center, space-between);

//       .title {
//         @include fontNormal(0.8em, 1em, #fff);
//         text-transform: uppercase;
//         margin: 0px;

//         @include breakpoint(desktop) {
//           font-size: 0.9em;
//         }
//       }
//       // .selectdropdown{

//       // }
//     }
//     &__balance {
//       margin: 0;
//       @include rowflexx(flex-end);
//       // gap: 10px;
//       gap: 5px;
//       span {
//         @include rowflexx(center);
//         gap: 10px;
//       }
//       .wallet__curr {
//         margin: 0;
//         margin-bottom: 2px;
//         @include fontSemiBold(1em, 1em, #fff);
//         opacity: 0.8;
//         @include breakpoint(mobileonly) {
//           font-size: 1em;
//         }
//         @include breakpoint(desktop) {
//           font-size: 1em;
//         }
//       }
//       span p:nth-child(1) {
//         margin: 0;
//         margin-bottom: 2px;
//         @include fontSemiBold(1.5em, 1em, #fff);
//         @include breakpoint(mobileonly) {
//           font-size: 1.5em;
//         }
//         @include breakpoint(desktop) {
//           font-size: 1.8em;
//         }
//       }
//       span i {
//         color: #fff;
//         transition: all 0.5s ease;
//         font-size: 1.5em;

//         @include breakpoint(mobileonly) {
//           font-size: 1em;
//         }
//         @include breakpoint(desktop) {
//           font-size: 1.5em;
//         }
//       }
//     }
//     &__details {
//       margin: 0px;
//       @include rowflexx(center);
//       gap: 10px;
//       @include fontMedium(1.1em, 1em, #fff);
//       font-style: italic;
//       cursor: pointer;
//       height: 20px;
//       i {
//         font-size: 1.3em;
//         color: #fff;
//       }
//     }
//     &__claimbutton {
//       @include rowflex(flex-end, center);
//       gap: 1rem;
//       cursor: pointer;

//       .arrowhand {
//         position: relative !important;
//         width: auto;
//       }

//       @include breakpoint(mobileonly) {
//         margin-top: -1.5rem;
//         margin-bottom: -1rem;
//       }
//     }

//     &__bottom {
//       @include colflex(flex-start, space-between);
//       gap: 20px;
//       height: 109px;
//     }
//     &__btns {
//       @include rowflexx(center);
//       gap: 20px;
//     }
//     &__btn {
//       min-width: 30px !important;
//       width: fit-content !important;
//       // padding: 0px 20px 0px 20px !important;
//       height: 50px !important;
//       border-radius: 5px !important;
//       background-color: $btn;
//       color: $btncolor;
//       font-size: 1em;
//       white-space: nowrap;
//       @include rowflex(center, center);
//       gap: 10px;
//       @include breakpoint(mobileonly) {
//         @include fontSemiBold(0.7em, 1em, $btncolor);
//       }

//       &.--outline {
//         background-color: transparent;
//         border: 2.5px solid $btn !important;
//         color: $btn;
//         font-weight: 600;
//       }
//     }
//   }
// }

// @mixin card($bkg, $color, $btn) {
//   height: 270px;
//   height: 350px;
//   cursor: pointer;
//   @include colflex(flex-start, space-between);

//   @include breakpoint(mobileonly) {
//     // height: 260px;
//     @include colflexx(flex-start);
//   }
//   .singlecard {
//     position: relative;
//     *p {
//       margin: 0px !important;
//     }
//     &__top,
//     &__bottom {
//       z-index: 10;
//       width: 100%;
//       @include rowflex(flex-end, space-between);
//       img {
//         width: 50px;
//       }
//     }
//     &__top {
//       @include rowflex(flex-start, space-between);
//       i {
//         font-size: 2em;
//         color: #fff;
//       }
//     }

//     &__name,
//     &__balance {
//       @include colflexx(flex-start);
//       gap: 10px;

//       p {
//         margin-bottom: 0px !important;
//         letter-spacing: 0.1em;
//       }
//     }
//     &__number {
//       z-index: 10;
//       @include fontNormal(1.5em, 1em, $color);
//       @include rowflexx(center);
//       font-style: italic;
//       gap: 10px;
//       i {
//         font-size: 1em;
//         color: #fff;
//       }
//     }
//     &__holder {
//       text-transform: uppercase;
//       @include fontMedium(1em, 1em, $color);
//     }
//     &__balance {
//       p:nth-child(1) {
//         @include fontNormal(1em, 1em, $color);
//       }
//       p:nth-child(2) {
//         @include fontSemiBold(1.5em, 1em, $color);
//       }
//     }

//     &__gg {
//       z-index: 1;
//       position: absolute;
//       top: -50px;
//       bottom: 0;
//       margin: 0 auto;
//       left: 0;
//       right: -200px;
//       width: 500px;
//       opacity: 0.5;
//       @include breakpoint(mobileonly) {
//         width: 350px;
//         top: 0px;
//       }
//     }
//   }
// }

// @mixin transreview($light, $border, $btn) {
//   .transfinancreview {
//     width: 400px !important;

//     @include breakpoint(mobileonly) {
//       width: 70vw !important;
//     }

//     @include breakpoint(desktop) {
//       width: 100% !important;
//     }
//     *.backcontinue {
//       width: 100% !important;
//       .platform {
//         &.platform_mainbtn {
//           background-color: $btn;
//           &:disabled {
//             background-color: $gray-800 !important;
//           }
//         }
//         &.platform_secbtn {
//           border-color: $btn !important;
//           color: $btn;
//         }
//       }
//     }
//     .transreview {
//       background-color: $light;
//       border: 0.5px solid $border;
//       &__single:not(:last-child) {
//         // background-color: red;
//         padding: 10px 0px;
//         border-bottom: 1px dashed $border;
//       }
//       &__single {
//         p:nth-child(1) {
//           @include fontNormal(1em, 1em, $border);
//         }
//         p:nth-child(2) {
//           @include fontBold(1em, 1em, $border);
//         }
//       }
//     }
//   }
// }

@mixin radiooption($active, $light) {
  .radiooption {
    &.--active {
      border: 1px solid $active;
      background-color: $light;
      .radiooption__left {
        p:nth-child(1) {
          @include fontBold(1.1em, 1em, $active);

          @include breakpoint(desktop) {
            @include fontBold(1.3em, 1em, $active);
          }
        }
      }
    }
    input[type="radio"] {
      &:checked + label:before {
        border-color: $active;
      }
      &:checked + label + div.paymentoption-detail {
        p:nth-child(1) {
          color: $active;
        }
      }
    }
    label {
      &:after {
        background: $active;
      }
    }
  }
}

@mixin platform($pry, $sec) {
  .platform {
    &.platform_mainbtn {
      background-color: $pry;
      &:disabled {
        background-color: $sec !important;
      }
    }
    &.platform_secbtn {
      border-color: $pry !important;
      color: $pry;
    }
  }
}

@mixin overflowScroll($track, $thumb) {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: $track;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $thumb;
    border-radius: 6px;
  }
}

.ant-float-btn-body {
  background-color: #a2cc49 !important;
  // background-color: $vesti-700 !important;

  .ant-float-btn-icon {
    color: $vesti-902 !important;
  }
}

// .popup {
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   z-index: 999;
//   background: white;
//   padding: 20px;
//   box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
//   border-radius: 10px;
//   @include breakpoint(mobileonly) {
//     width: 90%;
//   }
//   .iframe-Style {

//     iframe{
//       height: "70vh";
//       width: "30vw";
//       @include breakpoint(mobileonly) {
//         width: "80vw";
//       }
//     }
//   }

//   .button-container {
//     width: 100%;
//     display: flex;
//     justify-content: flex-end;

//     .popup-button {
//       width: 50%;
//       margin-top: 1rem;
//       background-color: #a2cc49;
//       color: #ffffff;
//       padding: 10px;
//       border-radius: 10px;
//     }
//   }
// }
