@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "shnebold";
  src: url("./assets/webfontkit/sohne-dreiviertelfett-webfont.woff2")
      format("woff2"),
    url("./assets/webfontkit/sohne-dreiviertelfett-webfont.woff") format("woff");
  /* font-weight: normal;
  font-style: normal; */
}

@font-face {
  font-family: "shnehalbfett";
  src: url("./assets/webfontkit/sohne-halbfett-webfont.woff2") format("woff2"),
    url("./assets/webfontkit/sohne-halbfett-webfont.woff") format("woff");
  /* font-weight: normal;
  font-style: normal; */
}

@font-face {
  font-family: "shnekrftig";
  src: url("./assets/webfontkit/sohne-kraftig-webfont.woff2") format("woff2"),
    url("./assets/webfontkit/sohne-kraftig-webfont.woff") format("woff");
  /* font-weight: normal;
  font-style: normal; */
}

@font-face {
  font-family: "shneleicht";
  src: url("./assets/webfontkit/sohne-leicht-webfont.woff2") format("woff2"),
    url("./assets/webfontkit/sohne-leicht-webfont.woff") format("woff");
  /* font-weight: normal;
  font-style: normal; */
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  width: 35%;
}

@media screen and (max-width: 768px) {
  iframe {
    width: 90%; /* Set to full width on smaller screens */
  }
  .popup {
    width: 90%;
  }
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.popup-button {
  width: 50%;
  margin-top: 1rem;
  background-color: #a2cc49;
  color: #ffffff;
  padding: 10px;
  border-radius: 10px;
}

.mainStackpopup {
  width: 450px;
  height: 660px;
}

/* h-[700px] w-[450px] */

@media screen and (max-width: 768px) {
  iframe {
    width: 90%;
  }
  .mainStackpopup {
    width: 500px;
    height: 700px;
    overflow-x: auto;
  }
}

.blur-bg {
  position: relative;
  margin-bottom: 1rem;
  background-color: rgba(179, 179, 179, 0.2);
  overflow: hidden;
  padding: 4px 10px;
  border-radius: 20px;
}

.blur-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(50px);
  background-color: rgba(179, 179, 179, 0.8);
  z-index: -1;
}

@media screen and (max-width: 768px) {
  .blur-bg {
    position: relative;
    margin-bottom: 1rem;
    background-color: rgba(179, 179, 179, 0.2); /* Slightly transparent */
    overflow: hidden; /* Prevent content from overflowing */
    padding: 4px 10px;
    border-radius: 20px;
  }
}

.h1bmarquee {
  /* display: inline-block; */
  /* position: absolute;  */
  will-change: transform;
  animation: marquee 30s linear infinite;
  width: fit-content;
}

@keyframes marquee {
  from {
    transform: translateX(100%); /* Start at the right */
  }
  to {
    transform: translateX(-100%); /* End at the left */
  }
}

.newMiai-animatedBg {
  position: absolute;
  left: 41.68%;
  right: -5%;
  top: 0;
  bottom: 25%;
  animation: change 15s ease-in-out infinite;
  background: conic-gradient(
    from 180deg at 50% 50%,
    #2134e1 0deg,
    #4a59e6 70.5deg,
    #67a948 162.37deg,
    #e8aef3 233.62deg,

    #67a948 162.37deg,
    #2134e1 360deg
  );
  filter: blur(120px);
  background-size: 400% 400%;
  border-radius: 20%;
  border-bottom-left-radius: 100%;
  z-index: 1;
}

@keyframes change {
  0% {
    background-position: 0 50%;
    transform: translateX(18rem);
  }

  25% {
    background-position: 50% 100%;
    transform: matrix(0.88, 0.14, -0.6, 0.98, 0, 0);
  }

  50% {
    background-position: 100% 50%;
    transform: matrix(2, 0.3, 0, 1, 1, 0);
  }
  75% {
    background-position: 0% 50%;
    transform: matrix(0.68, 0.24, -0.26, 0.98, 0, 0);
  }
  100% {
    background-position: 50 50%;
    transform: translateX(18rem);
  }
}

/*  */

.lightBg {
  position: absolute;
  left: 41.68%;
  right: -5%;
  top: 0;
  bottom: 25%;
  animation: changeSS 5s ease-in-out infinite;
  background: conic-gradient(
    from 45deg at 50% 50%,
    180deg at 50% 50%,

    #2134e1 0deg,
    #4a59e6 70.5deg,
    #6683cc 162.37deg,
    #a72272 310.5deg,
    #9a2cab 360deg
  );
  filter: blur(100px);
  background-size: 400% 400%;
}

@keyframes changeSS {
  0% {
    background-position: 0% 50%;
    transform: scaleX(-3rem);
  }

  50% {
    background-position: 100% 50%;
    transform: scaleX(12rem);
    transform: translateX(10rem);
  }

  100% {
    background-position: 0 50%;
    transform: scaleX(-5rem);
  }
}

@keyframes rotateInfinite {
  from {
    transform: rotate(0deg);
    width: 34px;
  }
  to {
    transform: rotate(360deg);
    width: 34px;
  }
}

.rotate-animation {
  animation: rotateInfinite 10s linear infinite;
}

@keyframes movingBackground {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 0%;
  }
}
