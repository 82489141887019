@import "../../variables.scss";

.floating-button-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  @include breakpoint(mobileonly) {
    left: 20px;
    bottom: 7px;
  }

  .floating-button {
    background-color: #a2cc49;
    border: none;
    border-radius: 50%;
    @include rowflex(center, center);
    @include fontSemiBold(0.8em, 1em, $gray-800);
    width: 90px;
    height: 90px;
    cursor: pointer;
    &:hover {
      border: 2px solid #bbf73b;
      background-color: $vesti-901;
      color: $vesti-100;
    }

    @include breakpoint(mobileonly) {
      @include fontBold(0.6em, 1em, $gray-800);
      width: 55px;
      height: 55px;
    }
    @include breakpoint(phablet) {
      @include fontBold(0.65em, 1em, $gray-800);
      width: 55px;
      height: 55px;
    }
  }
}
