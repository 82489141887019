@keyframes slideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  /* 50% {
    transform: translateX(10%);
    opacity: 1;
  } */
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
    display: none;
  }
}

.paymentclaritySection {
  animation: slideIn 2s ease-in-out;
}

/* slideOut 4s ease-in-out 10s forwards */

.paymentclarityoutSection {
  animation: slideOut 2s ease-in-out forwards;
}

@import url("https://fonts.googleapis.com/css?family=Raleway&display=swap");

:root {
  --light-grey: #f6f9fc;
  --dark-terminal-color: #0a2540;
  /* --accent-color: #635bff; */
  --radius: 5px;
}

/* body {
  padding: 20px;
  font-family: "Raleway";
  display: flex;
  justify-content: center;
  font-size: 1.2em;
  color: var(--dark-terminal-color);
} */

/* main {
  width: 480px;
} */

#payment-form > * {
  margin: 10px 0;
}

#submit {
  /* background-color: var(--accent-color); */
  background-color: #068b1d;
}

#submit {
  /* background: var(--accent-color); */
  background: #068b1d;
  border-radius: var(--radius);
  color: white;
  border: 0;
  padding: 12px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
}
#submit:hover {
  filter: contrast(115%);
}
#submit:active {
  transform: translateY(0px) scale(0.98);
  filter: brightness(0.9);
}
#submit:disabled {
  opacity: 0.5;
  cursor: none;
}

input,
select {
  display: block;
  font-size: 1.1em;
  width: 100%;
  margin-bottom: 10px;
}

label {
  display: block;
}

a {
  color: var(--accent-color);
  font-weight: 900;
}

small {
  font-size: 0.6em;
}

/* fieldset,
input,
select {
  border: 1px solid #efefef;
} */

#payment-form {
  border: #f6f9fc solid 1px;
  border-radius: var(--radius);
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%),
    0 30px 60px -30px rgb(0 0 0 / 30%);
}

#messages {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New";
  display: none; /* hide initially, then show once the first message arrives */
  background-color: #0a253c;
  color: #00d924;
  padding: 20px;
  margin: 20px 0;
  border-radius: var(--radius);
  font-size: 0.7em;
}

.custom-spinner .ant-spin-dot-item {
  background-color: green; /* Change the color to green */
}

/* :where(.css-dev-only-do-not-override-nllxry).ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon {
  background-color: #068b1d;
  border-color: #068b1d;
}
:where(.css-dev-only-do-not-override-nllxry).ant-steps
  .ant-steps-item-process
  .ant-steps-item-icon {
  background-color: #ccd2cd;
  border-color: #dde8df;
}
:where(.css-dev-only-do-not-override-nllxry).ant-steps
  .ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: #fff;
} */

:where(.css-dev-only-do-not-override-1v2lwm6).ant-steps
  .ant-steps-item-wait
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: white;
}

.anticon svg {
  color: white;
}

:where(.css-dev-only-do-not-override-nllxry).ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #068b1d;
}
:where(.css-dev-only-do-not-override-nllxry).ant-steps
  .ant-steps-item-container {
  outline: none;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}
:where(.css-dev-only-do-not-override-nllxry).ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #068b1d;
  width: 156px !important;
}

/* For steps in process */
.ant-steps-item-process .ant-steps-item-icon {
  background-color: green !important;
  border-color: green !important;
}

/* For finished steps */
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: green !important;
  border-color: green !important;
}

/* For waiting steps */
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: green !important;
  border-color: green !important;
}

/* For step titles */
.ant-steps-item-title {
  color: green !important;
}

/* For step descriptions */
.ant-steps-item-description {
  color: green !important;
}

/* For step tails (connecting lines) */
.ant-steps-item-tail::after {
  background-color: green !important;
}
